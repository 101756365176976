import { createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/page/disclaimer.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/page/privacy-policy.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/404.vue')
  }
]

const router = createRouter({
  // mode: 'history',
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
