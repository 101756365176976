<template>
  <div id="AppContainer" class="app_cosmo">
    <router-view />
  </div>
</template>

<style lang="scss">
.app_cosmo {
  height: inherit;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


*::-webkit-scrollbar {
  /*滚动条整体样式*/

  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/

  height: 1px;

}

*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #535353;

}

*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  background: #EDEDED;

}
</style>
