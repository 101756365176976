import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'



let app = createApp(App)

app.use(store).use(router).use(ElementPlus).mount('#app')

app.config.errorHandler = function(err, componentPublicInstance, info){
    console.error(err)
}

